import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./AdminPanel.css";
import { backend_path } from "../../constants/backend_path";
import clglogo from '../../Assets/pictures/logo.png'

const PaymentHistory = () => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [isSorted, setIsSorted] = useState(false);
  const [sortedHistory, setSortedHistory] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { key } = location.state || {};
  const printRef = useRef();

  useEffect(() => {
    if (!key && key !== "SsSaDmin153@gmail.com") {
      navigate("/");
    }

    const fetchPaymentHistory = async () => {
      try {
        const response = await axios.post(`${backend_path}/payment-history`);
        const sortedData = response.data.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );
        setPaymentHistory(sortedData);
      } catch (error) {
        console.error("Error fetching payment history:", error);
      }
    };
    fetchPaymentHistory();
  }, []);

  const toggleSortByDate = () => {
    setIsSorted(!isSorted);
    if (!isSorted) {
      const groupedByDate = paymentHistory.reduce((acc, payment) => {
        const date = new Date(payment.payment_date).toLocaleDateString();
        if (!acc[date]) acc[date] = { data: [], onlineTotal: 0, cashTotal: 0 };

        acc[date].data.push(payment);

        // Convert amount_paid to a number to avoid string concatenation
        const amount = parseFloat(payment.amount_paid.replace(/[^0-9.-]+/g, ""));

        if (payment.payment_mode.toLowerCase() === "online") {
          acc[date].onlineTotal += amount;
        } else if (payment.payment_mode.toLowerCase() === "cash") {
          acc[date].cashTotal += amount;
        }

        return acc;
      }, {});

      const sortedGroupedByDate = Object.keys(groupedByDate)
        .sort((a, b) => new Date(b) - new Date(a))
        .reduce((acc, key) => {
          acc[key] = groupedByDate[key];
          return acc;
        }, {});

      setSortedHistory(sortedGroupedByDate);
    }
  };

  // Function to handle printing specific date's payment history
  const handlePrint = (date) => {
    const printContent = document.getElementById(`print-${date}`);
    if (printContent) {
      const onlineTotal = sortedHistory[date].onlineTotal;
      const cashTotal = sortedHistory[date].cashTotal;
  
      const printWindow = window.open("", "", "width=900,height=650");
      printWindow.document.write(`
        <html>
          <head><title>Print Payment History</title></head>
          <body>
            <div class="logo text-center">
              <img src="${clglogo}" alt="clg-logo" style="width: 100px; height: auto;" />
              <h2>SUDHA SASEENDRAN SIDDHA MEDICAL COLLEGE AND HOSPITAL</h2>
              <p>Meecode, Kaliyakkavilai Post, Kanyakumari District - 629153</p>
            </div>
            <h2>Payment History for ${date}</h2>
            <p><strong>Online Total:</strong> ₹${onlineTotal}</p>
            <p><strong>Cash Total:</strong> ₹${cashTotal}</p>
            ${printContent.innerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  };
  

  return (
    <div className="payment-history-container">
      <div className="logo text-center">
                <img className='clg-logo' src={clglogo} alt='clg-logo' />
                <h2>SUDHA SASEENDRAN SIDDHA MEDICAL COLLEGE AND HOSPITAL</h2>
                <p>Meecode, Kaliyakkavilai Post, Kanyakumari District - 629153</p>
            </div>
      <div className="admin-panel">
        <h1 className="h1">Payment History</h1>
        <div className="button-container">
          <button
            className="history-button"
            onClick={() => navigate("/admin", { state: { key } })}
          >
            Admin Panel
          </button>
          <button
            className="history-button"
            onClick={() => navigate("/payment-request", { state: { key } })}
          >
            Payment Request
          </button>
          <button className="history-button" onClick={toggleSortByDate}>
            {isSorted ? "Show Original Order" : "Sort by Date"}
          </button>
        </div>

        <div className="content-container">
          <div className="table-container">
            {isSorted ? (
              Object.keys(sortedHistory).map((date) => (
                <div key={date} className="date-group">
                  <h2>{date}</h2>
                  <button onClick={() => handlePrint(date)} className="print-button1">
                    Print {date}'s Payment History
                  </button>
                  <p>Online: ₹{sortedHistory[date].onlineTotal}</p>
                  <p>Cash: ₹{sortedHistory[date].cashTotal}</p>

                  {/* Add Print Button for each date */}
                  

                  {/* Wrap the payment history for this date in a div */}
                  <div id={`print-${date}`}>
                    <table className="table">
                      <thead>
                        <tr className="tr">
                          <th className="th">Receipt Number</th>
                          <th className="th">Admission Number</th>
                          <th className="th">Reg No</th>
                          <th className="th">Name</th>
                          <th className="th">Email</th>
                          <th className="th">Phone No</th>
                          <th className="th">Online/Offline</th>
                          <th className="th">Transaction ID</th>
                          <th className="th">Payment Date</th>
                          <th className="th">Fee Type</th>
                          <th className="th">Amount Paid</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedHistory[date].data.map((payment, index) => (
                          <tr key={index} className="tr">
                            <td className="td">{payment.receipt_no}</td>
                            <td className="td">{payment.admission_number}</td>
                            <td className="td">{payment.regno}</td>
                            <td className="td">{payment.name}</td>
                            <td className="td">{payment.email}</td>
                            <td className="td">{payment.phone_no}</td>
                            <td className="td">{payment.payment_mode}</td>
                            <td className="td">{payment.transaction_id}</td>
                            <td className="td">
                              {new Date(payment.payment_date).toLocaleDateString()}
                            </td>
                            <td className="td">{payment.fee_type}</td>
                            <td className="td">₹{payment.amount_paid}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))
            ) : (
              <table className="table">
                <thead>
                  <tr className="tr">
                    <th className="th">Receipt Number</th>
                    <th className="th">Admission Number</th>
                    <th className="th">Reg No</th>
                    <th className="th">Name</th>
                    <th className="th">Email</th>
                    <th className="th">Phone No</th>
                    <th className="th">Online/Offline</th>
                    <th className="th">Transaction ID</th>
                    <th className="th">Payment Date</th>
                    <th className="th">Fee Type</th>
                    <th className="th">Amount Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentHistory.length ? (
                    paymentHistory.map((payment, index) => (
                      <tr key={index} className="tr">
                        <td className="td">{payment.receipt_no}</td>
                        <td className="td">{payment.admission_number}</td>
                        <td className="td">{payment.regno}</td>
                        <td className="td">{payment.name}</td>
                        <td className="td">{payment.email}</td>
                        <td className="td">{payment.phone_no}</td>
                        <td className="td">{payment.payment_mode}</td>
                        <td className="td">{payment.transaction_id}</td>
                        <td className="td">
                          {new Date(payment.payment_date).toLocaleDateString()}
                        </td>
                        <td className="td">{payment.fee_type}</td>
                        <td className="td">₹{payment.amount_paid}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11" className="no-data">
                        No payment history available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
