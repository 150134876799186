import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className='footer-div'>
      <div className='footer_section'>
        <div className='location'>
          <b>LOCATION</b>
          <p className='footer_section-p'>Mecode, Kaliakkavilai, Vilavancode Taluk. KanyaKumari District, Tamil Nadu 629-153</p>
        </div>
        <div className='Contact'>
          <b>CONTACT US</b>
          <p className='footer_section-p'>04651-216674</p>
          <p className='footer_section-p'>9042228199</p>
        </div>
        <div className='Email'>
        <b>EMAIL</b>
        <a href={`mailto:${encodeURIComponent('saseendraninstitute@gmail.com')}`}>saseendraninstitute@gmail.com</a>
        </div>
        <div className='WebsiteLinks'>
          <b>WEBSITE LINKS</b>
          <a  className='footer_section-p'target="_blank" href='https://nischennai.org/main/'>www.nischennai.org</a>
          <a  className='footer_section-p'target="_blank" href='https://www.ncismindia.org/'>www.ncismindia.org</a>
          <a  className='footer_section-p'target="_blank" href='https://ayush.gov.in/'>www.ayush.gov.in</a>
          <a  className='footer_section-p'target="_blank" href='https://www.tnmgrmu.ac.in/'>www.tnmgrmu.ac.in</a>
        </div>
      </div>
      <div className='copyright'>
  <p className='copyright-p'>© 2024 SSS MEDICAL COLLEGE & HOSPITAL. All rights reserved.</p>
</div>
    </footer>
  );
};

export default Footer;